@import '../variables/colors/_colors.scss';

#projects {
  padding: 0;
  position: relative;
  .clip {
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 45% 100%);
    background: $light-color;
  }
  color: $light-color;
  .mainContainer {
    border-radius: 8px;
    .projectContainer {
      height: 600px;
      padding: 50px 0;
      overflow: hidden;
      .nextHeading {
        opacity: 0;
        animation: opacity 0.2s ease 0s forwards;
      }
      .nextText {
        padding: 0;
        opacity: 0;
        animation: opacity 0.2s ease 0.2s forwards;
      }
      .nextVisit {
        opacity: 0;
        animation: opacity 0.2s ease 0.4s forwards;
      }
      .prevHeading {
        animation: translate 0.2s ease 0s forwards;
      }
      .prevText {
        animation: translate 0.2s ease 0.2s forwards;
      }
      .prevVisit {
        animation: translate 0.2s ease 0.4s forwards;
      }
      h3 {
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 4rem;
      }
      .logos {
        transition: all 0.2s;
        .logo {
          height: 250px;
          display: flex;
          align-self: center;
          transition: all 0.2s;
          svg {
            width: 100%;
          }
        }
      }
      .logos-mobile {
        display: none;
      }
      .logoNotSelected {
        filter: contrast(0.1) blur(5px);
      }
    }
    .projectInfo {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0;
      color: $light-color;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      overflow: scroll;
      p {
        padding: 10px;
        font-size: 1em;
      }
      a {
        text-decoration: none;
        color: $light-color;
        text-transform: uppercase;
        &:hover {
          color: $rose-color;
          text-decoration: underline;
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      height: 500px;
      .preview {
        button {
          height: 10px;
          width: 5px;
          margin: 10px;
          border-radius: 8px;
          transition: all 0.15s;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $rose-dark;
          &:hover svg {
            background: $background-color;
          }
        }
      }
    }
    .buttons-mobile {
      display: none;
    }
    .notSelected {
      background: $light-color;
    }
    .selected {
      background: $rose-dark;
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes translate {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  #projects {
    .clip {
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
    color: $light-color;
    .mainContainer {
      .projectContainer {
        height: auto;
        padding: 50px 0;
        h3 {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 2rem;
        }
        .logos {
          display: none;
          overflow: hidden;
        }
        .logos-mobile {
          display: flex;
          background: $light-color;
          width: 100vw;
          overflow: hidden;
          height: 300px;
          padding: 0;
          .mobileLogoContainer {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding: 25px;
            transition: all 0.2s;
            .logo {
              display: block;
              width: 60vw;
              svg {
                width: 60%;
              }
            }
          }
        }
      }
      .projectInfo {
        margin-top: 0px;
        margin-bottom: 20px;
        min-height: 450px;
        padding: 0;
        color: $light-color;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: scroll;
        p {
          padding: 10px;
          font-size: 1em;
        }
        a {
          text-decoration: none;
          color: $light-color;
          text-transform: uppercase;
          &:hover {
            color: $rose-color;
            text-decoration: underline;
          }
        }
      }
      .buttons {
        display: none;
      }
      .buttons-mobile {
        display: flex;
        height: auto;
        justify-content: center;
        .preview {
          display: flex;
          flex-direction: row;
          button {
            height: 10px;
            width: 10px;
            margin: 20px;
            border-radius: 8px;
            transition: all 0.15s;
            align-items: center;
            justify-content: center;
            border: 1px solid $rose-dark;
            &:hover svg {
              background: $rose-color;
            }
            &.selected {
              background: $rose-color;
            }
          }
        }
      }
    }
  }
}
