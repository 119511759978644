@import '../variables/colors/_colors.scss';

#contacts {
  h2 {
    color: $light-color;
  }
  a {
    font-size: 1.3rem;
    color: $light-color;
    text-decoration: none;
    margin: 5px;
    transition: all 0.2s;
  }
  .cv {
    a {
      margin: 5px 20px;
    }
  }
  .cv,
  .email {
    transition: all 0.5s;
    a {
      &:hover {
        color: $rose-color;
      }
    }
  }
  .social,
  .cv,
  .email {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
  .social {
    a {
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;
      border: 2px solid $light-color;
      &:hover {
        transform: scale(1.2);
        background: $rose-color;
        border-color: $rose-color;
      }
    }
  }
}

@media (max-width: 767px) {
  #contacts {
    a {
      font-size: 1rem;
    }
    .cv {
      a {
        margin: 5px 10px;
      }
    }
    .social,
    .cv,
    .email {
      margin-top: 20px;
    }
    .social {
      a {
        width: 40px;
        height: 40px;
      }
    }
  }
}
