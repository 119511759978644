@import '../variables/colors/_colors.scss';

.pageNotFound {
  margin: 0;
  padding: 0;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $light-color;
  p {
    font-size: 10rem;
  }
  a {
    font-size: 1.2rem;
    color: $light-color;
  }
}

@media (max-width: 767px) {
  .pageNotFound {
    height: calc(100vh - 80px);
    p {
      font-size: 8rem;
    }
    a {
      font-size: 1rem;
    }
  }
}
