@import '../variables/colors/_colors.scss';

.cv-container {
  position: relative;
  margin-top: -80px;
  background: $light-color;
  h3 {
    margin-top: 20px;
    text-transform: uppercase;
  }
  .bg-clip {
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
    background: $rose-dark;
  }
  #cv {
    background: $light-color;
    position: relative;
    padding: 25px;
    padding-top: 120px;
    color: $rose-dark;
    .bg {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      background: $rose-dark;
      height: 80px;
    }
    .cv-header {
      border-bottom: 2px solid $rose-color;
      h1,
      h2 {
        text-align: center;
      }
      h1 {
        font-weight: bold;
      }
      .social {
        display: flex;
        justify-content: center;
        margin: 30px 0;
        a {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $rose-color;
          font-size: 1.5rem;
          color: $light-color;
          margin: 5px;
        }
      }
      .cv-contact {
        display: flex;
        justify-content: space-around;
        a {
          color: $rose-dark;
        }
      }
    }
    #photo {
      background-image: url('/my_photo.webp');
      width: 250px;
      height: 250px;
      border-radius: 50%;
      background-size: cover;
      background-position: top;
    }
    .skills {
      ul {
        list-style-type: none;
        padding-left: 0;
        li {
          display: inline-block;
          margin-right: 5px;
          &::after {
            content: ' - ';
          }
          &:last-of-type {
            &::after {
              content: '';
            }
          }
        }
      }
    }
    .cv-education,
    .cv-experience {
      div {
        margin: 15px 0;
        p {
          margin-bottom: 5px;
          &:first-child {
            font-weight: bold;
          }
        }
        a {
          color: $rose-color;
        }
        &.col-4 {
          p {
            text-align: right;
          }
        }
      }
    }
  }
}
.cv-download {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: $light-color;
    font-size: 1.5rem;
    transition: all 0.2s;
    &:hover {
      text-decoration: underline;
      color: $rose-color;
    }
  }
}

@media (max-width: 767px) {
  .cv-container {
    #cv {
      padding-top: 100px;
      .bg {
        display: none;
      }
      #photo {
        margin: auto;
        margin-top: 15px;
      }
      .info {
        div {
          margin: 15px 0;
        }
      }
      .cv-education,
      .cv-experience {
        div {
          margin: 15px 0;
          p {
            margin-bottom: 5px;
            &:first-child {
              font-weight: bold;
            }
          }
          a {
            color: $rose-color;
          }
          &.col-4 {
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .cv-download {
    a {
      font-size: 1.2rem;
    }
  }
}

@media print {
  .cv-container {
    #cv {
      padding: 25px;
      padding-top: 120px;
      .bg {
        height: 80px;
      }
      .cv-header-container {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
      .info-container {
        grid-template-columns: 4fr 2fr 3fr;
      }
      #print-break {
        break-after: page;
      }
      .print-padding {
        padding-top: 50px;
      }
    }
    .cv-education,
    .cv-experience {
      div {
        &.col-4 {
          p {
            text-align: left;
          }
        }
      }
    }
  }

  .cv-download {
    display: none;
  }
  #__replain_widget {
    display: none;
  }
}
