@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import './variables/colors/_colors.scss';

.logo-container {
  text-align: center;
}

body {
  position: relative;
  background-color: $rose-dark;
}

h2,
h1,
button,
ul {
  letter-spacing: 1px;
  font-weight: 200;
}
section {
  padding: 50px 0;
}

button {
  color: $light-color;
  background: none;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  font-size: 1rem;
  border-right: 1px solid $light-color;
  &:last-child {
    border-right: none;
  }
  &:focus {
    outline: none;
  }
  &:hover,
  .contact a:hover,
  .Navigation a:hover {
    color: $rose-color;
  }
  &.activeLangue {
    color: $rose-color;
  }
}

.svgContainer-mobile {
  position: absolute;
  top: -15%;
  right: 5%;
}
.svgContainer-mobile .logoAnimated {
  width: 70px;
  z-index: 100;
}

.col-10 {
  border-left: 2px solid $complimentary-color;
  padding: 0 20px;
}

.absolute {
  position: absolute;
  right: -5%;
  bottom: 0;
}

footer {
  color: $light-color;
  p {
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
  }
}

@import './modules/_contacts.scss';
@import './modules/_project.scss';
@import './modules/_education.scss';
@import './modules/_about.scss';
@import './modules/_greeting.scss';
@import './modules/_header.scss';

@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  section {
    padding: 35px 0;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }
}
