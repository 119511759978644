@import './variables/colors/_colors.scss';

header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 100;
  transition: all 0.2s;
  &.active {
    background-color: $black-color;
    backdrop-filter: blur(10px);
  }

  .navbar {
    .buttons {
      text-align-last: right;
    }
    .navbar-button {
      display: none;
    }
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 30px;
    flex-wrap: nowrap;
    justify-content: space-between;
    .mobile-navigation {
      display: none;
    }
    .navigation {
      display: flex;
      align-items: center;
      transition: all 0.3s;
      a {
        color: $light-color;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 200;
        margin: 0 15px;
        letter-spacing: 1px;
        text-decoration: none;
        transition: all 0.2s;
        &:hover {
          color: $rose-color;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  header {
    height: 60px;
    &.blocked {
      background: $rose-dark;
    }
    &.unBlocked {
      background: $black-color;
      transition-delay: 1s;
    }
    button {
      margin: 0px 0px;
      padding: 10px 10px;
      font-size: 0.8rem;
    }
    .navbar {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      position: relative;
      .logo-container {
        svg {
          width: 50px;
          height: 50px;
        }
      }
      .navbar-button {
        display: inline-block;
        border: none;
        width: 40px;
        height: 40px;
        padding: 0;
        position: relative;
        .button-toggle-top,
        .button-toggle-bottom {
          position: absolute;
          left: 5px;
          width: 30px;
          height: 2px;
          background: $rose-color;
          transition: all 0.2s;
          &.open {
            width: 20px;
            left: 10px;
            top: 20px;
          }
        }
        .button-toggle-top {
          top: calc(50% + 3px);
          &.open {
            transform: rotate(-45deg);
          }
        }
        .button-toggle-bottom {
          top: calc(50% - 3px);
          &.open {
            transform: rotate(45deg);
          }
        }
      }
      padding-right: 10px;
      padding-left: 10px;
      .navigation {
        display: none;
      }
      .mobile-navigation {
        position: absolute;
        left: 0;
        overflow: hidden;
        top: 60px;
        display: flex;
        flex-direction: column;
        background: $rose-dark;
        transition: all 1s;
        width: 100vw;
        a {
          color: $light-color;
          margin: 20px;
          text-transform: uppercase;
          &:hover {
            color: $rose-color;
          }
        }
        &.open {
          height: 100vh;
        }
        &.closed {
          height: 0;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  header {
    .navbar {
      padding-right: 15px;
      padding-left: 15px;
      flex-wrap: nowrap;
      .navigation {
        a {
          font-size: 1 rem;
        }
      }
    }
  }
}

@media print {
  header {
    display: none;
    overflow: hidden;
  }
}
