@import '../variables/colors/_colors.scss';

.aboutContainer {
  background: $light-color;
  #about {
    .skills {
      align-self: center;
      ul {
        list-style-type: none;
        display: flex;
        padding-left: 0;
        color: $light-color;
        li {
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 10px 15px;
          font-size: 1.2rem;
          background: $rose-dark;
          line-height: 1rem;
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
    #message {
      font-size: 1rem;
      letter-spacing: 0.2px;
    }
  }
}

@media (max-width: 792px) {
  #about {
    .skills {
      ul {
        flex-wrap: wrap;
        color: $light-color;
        li {
          font-size: 1rem;
        }
      }
    }
    #message {
      font-size: 0.8rem;
    }
  }
}
