@import '../variables/colors/_colors.scss';

.greetingArea {
  height: 100vh;
  margin-top: -80px;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  #video {
    z-index: 1;
  }
  #greeting {
    position: absolute;
    height: 100px;
    width: 700px;
    top: calc(50% - 50px);
    right: calc(50% - 350px);
    text-align: center;
    z-index: 50;
    h1 {
      color: $light-color;
      text-transform: uppercase;
      span {
        color: $rose-color;
        font-weight: 600;
      }
    }
    button {
      margin: auto;
      border: 1px solid $light-color;
      padding: 5px 15px;
      margin-top: 25px;
      transition: all 0.2s;
      a {
        color: $light-color;
        letter-spacing: 1px;
        font-weight: 200;
        font-size: 1.5rem;
        text-decoration: none;
      }
      &:hover {
        border-color: $rose-color;
        background-color: $rose-color;
        transform: scale(1.1);
      }
    }
  }
  #filter {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $filter-color;
    opacity: 0.7;
  }
}

@media (max-width: 767px) {
  .greetingArea {
    margin-top: -80px;
    position: relative;
    overflow: hidden;
    padding-top: 0;
    #greeting {
      width: 250px;
      top: calc(50% - 50px);
      right: calc(50% - 125px);
      h1 {
        span {
          color: $rose-color;
          font-weight: 600;
        }
      }
      button {
        margin: auto;
        border: 1px solid $light-color;
        padding: 5px 15px;
        margin-top: 25px;
        transition: all 0.2s;
        a {
          color: $light-color;
          letter-spacing: 1px;
          font-weight: 200;
          font-size: 1.5rem;
          text-decoration: none;
        }
        &:hover {
          border-color: $rose-color;
          background-color: $rose-color;
          transform: scale(1.1);
        }
      }
    }
  }
}
